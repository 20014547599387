<template>
  <!-- Main section start -->
  <section class="h-auto md:h-auto lg:h-[115vh]">
      <div class="h-auto md:h-auto lg:h-[115vh] page-bg">
          <div class="p-6">
              <img v-motion-pop-visible src="../assets/MysticBertXRoobetLogo.png" alt="logo">
          </div>
          <div class="flex flex-col lg:flex-row justify-center items-center p-8 lg:p-0 gap-10 lg:gap-24 2xl:mt-8">
              <div class="flex flex-col px-0 lg:px-0">
                  <a href="https://roobet.com/?ref=mysticbert" target="_blank">
                    <img v-motion-slide-visible-left src="../assets/join-roobet.png" class="w-[95%]" alt="sign-up">
                  </a>
              </div>
              <div>
                  <img v-motion-pop-visible src="../assets/roobet-logo.png" alt="roobet-logo">
              </div>
          </div>
      </div>
  </section>
  
  <section class="h-auto lg:-mt-24">
      <div class="flex flex-col px-10 gap-8 lg:gap-14">
          <div class="flex flex-col-reverse lg:flex-row justify-center items-center lg:gap-20">
              <img v-motion-slide-visible-left src="../assets/affiliate.png" alt="affiliate">
              <img v-motion-pop-visible src="../assets/affiliate-img.png" alt="affiliate image">
          </div>
          <div class="flex flex-col lg:flex-row justify-center items-center lg:gap-20">
              <img v-motion-pop-visible src="../assets/deposit-img.png" alt="deposit image">
              <img v-motion-slide-visible-left src="../assets/deposit.png" alt="deposit">
          </div>
      </div>
  </section>
  
  <section class="h-auto leaderboard-bg">
    <div class="flex justify-center px-5 md:px-8">
      <div class="flex flex-col justify-center items-center mt-8 mb-14 lg:my-16 p-2">
        <div v-motion-pop-visible>
           <div class="flex justify-center">
            <img src="../assets/mysticbert-logo.png" class="w-[60%]" alt="mysticbert-logo">
           </div>
          <img src="../assets/leaderboard.png" alt="leaderboard">
        </div>
        <div class="flex flex-col md:flex-row gap-2 mt-4" style="font-family: Archivo Black, sans-serif;">
          <button @click="selectedLeaderboard = 1" v-motion-slide-visible-right :class="{'bg-[#800080] border-[#800080]': selectedLeaderboard == 1}" class="text-white bg-transparent hover:bg-[#800080] border hover:border-[#800080] font-base py-4 px-20 relative uppercase rounded-xl">
              Roobet
          </button>
          <button @click="selectedLeaderboard = 2" v-motion-slide-visible-left :class="{'bg-[#800080] border-[#800080]': selectedLeaderboard == 2}" class="text-white bg-transparent hover:bg-[#800080] border hover:border-[#800080] font-base py-4 px-[70px] relative uppercase rounded-xl">
              Packdraw
          </button>
        </div>
        <div v-if="selectedLeaderboard == 1">
          <RoobetLeaderboardSection/>
        </div>
        <div v-if="selectedLeaderboard == 2">
           <PackdrawLeaderboardSection/>
        </div>
      </div>
    </div>
  </section>
  

  <section>
    <div class="flex flex-col justify-center items-center" style="font-family: Black Han Sans, sans-serif;">
        <div class="flex flex-col justify-center items-center px-8">
          <div v-motion-pop-visible class="text-white text-xl lg:text-4xl text-center" style="text-shadow: #FFFFFF 5px 0 15px;">JOIN MYSTICBERT LEADEARBOARD BY FOLLOWING THE LINK</div>
          <a v-motion-pop-visible href="https://roobet.com/?ref=mysticbert"><div class="text-[#FAFF00] text-xl lg:text-4xl mt-2" style="text-shadow: #FAFF00 5px 0 15px;">ROOBET.COM/MYSTICBERT!</div></a>
        </div>
        <div class="flex justify-start lg:px-44 leading-10 mt-8 lg:mt-14 uppercase px-8">
          <div class="text-white" v-motion-slide-visible-left>
            DISCLAIMER:
            <br>Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are playing. This helps prevent leaderboard abuse:
            <br><br>Games with an RTP of 97% or less will contribute 100% of the amount wagered to the leaderboard.
            <br>Games with an RTP above 97% will contribute 50% of the amount wagered to the leaderboard.
            <br>Games with an RTP of 98% and above will contribute 10% of the amount wagered to the leaderboard.
          </div>
          
        </div>
    </div>
  </section>

  <ChallengesSection/>
</template>

<script>
import { defineComponent } from 'vue';
import ChallengesSection from '@/sections/ChallengesSection.vue';
import RoobetLeaderboardSection from '@/sections/RoobetLeaderboardSection.vue';
import PackdrawLeaderboardSection from '@/sections/PackdrawLeaderboardSection.vue';

export default defineComponent({
  components: {
      RoobetLeaderboardSection,
      PackdrawLeaderboardSection,
      ChallengesSection
  },
  data() {
    return {
      loading: false,
      selectedLeaderboard: 1,
    };
  },
  methods: {
     
  },
});
</script>

<style scope>
body {
  background-color: #110011;
}
.page-bg {
  background-image: url('../assets/Big-M-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
