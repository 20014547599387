<template>
    <!-- Main Section -->
    <div>
          <div v-motion-pop-visible>
            <div class="text-center mt-12 text-white text-2xl" style="font-family: Archivo Black, sans-serif;">{{ timer }}</div>
          </div>
          <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl mt-10" style="font-family: Archivo Black, sans-serif;">Leaderboard is Coming ...</div>
          <div v-if="loading == false"  class="hidden md:block">
            <div class="flex flex-col justify-center items-center mx-auto" style="font-family: Archivo Black, sans-serif;">
            <div class="mt-14 md:mt-20 grid md:gap-14">
            <div v-motion-slide-visible-left>
              <img
                src="../assets/1st.png"
                class="w-full md:w-[100%] mx-auto"
                alt="1st"
              />
              <div class="grid grid-cols-3 md:ml-[270px]">
                <span
                  class="md:-mt-11 lg:-mt-[60px] md:text-base lg:text-lg md:-ml-20 lg:ml-0 text-white font-semibold"
                  >{{ top3.length > 0 && top3[0] != undefined ? maskString(top3[0].username) : '...' }}</span
                >
                <span
                  class="md:-mt-11 lg:-mt-[60px] md:-ml-12 lg:ml-9 md:text-base lg:text-lg text-[#FAFF00] font-semibold"
                  >Prize $ {{ getRewardValue(0) }}</span
                >
                <span
                  class="md:-mt-[60px] lg:-mt-[80px] md:-ml-3 lg:ml-6 md:text-base lg:text-lg text-black font-semibold"
                  >Wagered: <br>
                  $ {{ top3.length > 0 && top3[0] != undefined ? top3[0].wagerAmount.toFixed(2) : '...' }}</span
                >
              </div>
            </div>
            <div v-motion-slide-visible-left>
              <img
                src="../assets/2nd.png"
                class="w-full md:w-[85%] mx-auto"
                alt="2nd"
              />
              <div class="grid grid-cols-3 ml-[300px]">
                <span
                  class="md:-mt-10 lg:-mt-14 md:-ml-20 lg:ml-0 md:text-sm lg:text-base text-white font-semibold"
                  >{{ top3.length > 0 && top3[1] != undefined ? maskString(top3[1].username) : '...' }}</span
                >
                <span
                  class="md:-mt-10 lg:-mt-14 md:-ml-20 lg:ml-0 md:text-sm lg:text-base text-[#FAFF00] font-semibold"
                  >Prize $ {{ getRewardValue(1) }}</span
                >
                <span
                  class="md:-mt-14 lg:-mt-[70px] md:-ml-12 lg:-ml-7 md:text-sm lg:text-base text-black font-semibold"
                  >Wagered: <br>
                  $ {{ top3.length > 0 && top3[1] != undefined ? top3[1].wagerAmount.toFixed(2) : '...' }}</span
                >
              </div>
            </div>
            <div v-motion-slide-visible-right>
              <img
                src="../assets/3rd.png"
                class="w-full md:w-[70%] mx-auto"
                alt="3rd"
              />
              <div class="grid grid-cols-3 ml-[325px]">
                <span
                  class="md:-mt-8 lg:-mt-10 md:-ml-24 lg:ml-0 text-sm text-white font-semibold"
                  >{{ top3.length > 0 && top3[2] != undefined ? maskString(top3[2].username) : '...' }}</span
                >
                <span
                  class="md:-mt-8 lg:-mt-10 md:-ml-24 lg:-ml-8 text-sm text-[#FAFF00] font-semibold"
                  >Prize $ {{ getRewardValue(2) }}</span
                >
                <span
                  class="md:-mt-10 lg:-mt-[52px] md:-ml-[88px] lg:-ml-[80px] text-xs lg:text-base text-black font-semibold"
                  >Wagered: <br>
                  $ {{ top3.length > 0 && top3[2] != undefined ? top3[2].wagerAmount.toFixed(2) : '...' }}</span
                >
              </div>
            </div>
            </div>
          </div>
            <div class="flex justify-center mt-8 md:mb-8 text-xl" style="font-family: Saira Condensed, sans-serif">
              <table class="w-full lg:w-auto">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-left">
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-center">
                      User
                    </th>
                    <th
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-center">
                      Wagered
                    </th>
                    <th
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in top5" :key="index">
                    <td
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-left">
                      {{ getOrdinalSuffix(index + 4) }}
                    </td>
                    <td
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                      {{ maskString(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                      ${{ item.wagerAmount.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-[#FAFF00] font-semibold text-right">
                      {{ getRewardValue(index + 3) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="loading == false"  class="block md:hidden">
            <div class="flex justify-center mt-8 md:mb-8 text-xl" style="font-family: Saira Condensed, sans-serif">
              <table class="w-full lg:w-auto">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-left">
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-center">
                      User
                    </th>
                    <th
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-center">
                      Wagered
                    </th>
                    <th
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in top10" :key="index">
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-left">
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                      {{ maskString(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                      ${{ item.wagerAmount.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-[#FAFF00] font-semibold text-right">
                      {{ getRewardValue(index) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
         </div>
    </div>
    <section class="pb-8 pt-8 md:pb-14 md:pt-14">
      <div v-if="prevloading == false" class="flex flex-col justify-center items-center">
        <div>
            <img src="../assets/PrevLead.png" class="px-6" alt="prevlead">
        </div>
          <div class="flex justify-center mt-8 md:mb-14 text-xl" style="font-family: Saira Condensed, sans-serif">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-center">
                    Wagered
                  </th>
                  <th
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-10 text-white text-[18px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in prevLeaderboard" :key="index">
                  <td
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-semibold text-center">
                    ${{ item.wagerAmount.toFixed(2) }}
                  </td>
                  <td
                    class="px-1 md:px-[55px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[18px] text-[#FAFF00] font-semibold text-right">
                    {{ getPrevRewardValue(index) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment-timezone';
  
  export default {
    data() {
      return {
        top3: [],
        top5: [],
        top10: [],
        timer: '',
  
        prevLeaderboard: [],
  
        loading: false,
        prevloading: false,
      };
    },
    methods: {
      async init() {
        const baseUrl = process.env.VUE_APP_BASE_API;
        this.loading = true
        this.prevloading = true
  
        await axios.get(`${baseUrl}/api/packdraw/leaderboard`, 
          {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,}})
        .then(res => {
          const leaderboard = res.data.leaderboard;
  
          this.top3 = leaderboard.slice(0, 3).map((item) => {
            return item;
          });
  
          this.top5 = leaderboard.slice(3, 10).map((item) => {
            return item;
          });
  
          this.top10 = leaderboard.slice(0, 10).map((item) => {
            return item;
          });
          
        });
  
        await axios.get(`${baseUrl}/api/packdraw/leaderboard?previous=true`, 
        {headers: {'x-api-key': process.env.VUE_APP_X_API_KEY,}}
        ).then(rest => {
          const prevleaderboard = rest.data.leaderboard;
          
          this.prevLeaderboard = prevleaderboard.slice(0, 10).map((item) => {
            return item;
          });
        });
  
        this.prevloading = false
        this.loading = false
      },
      getOrdinalSuffix(n) {
        const s = ['th', 'st', 'nd', 'rd'],
          v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      },
      getRewardValue(rank) {
        const prizes = [500, 300, 200, 150, 100];
        if (rank >= 6) {
          return '$' + 50;
        }
        return '$' + prizes[rank];
      },
      getPrevRewardValue(rank) {
        const prizes = [500, 300, 200, 150, 100];
        if (rank >= 6) {
          return '$' + 50;
        }
        return '$' + prizes[rank];
      },
      maskString(str) {
        if (!str || str.length <= 4) return str;
  
        const start = str.slice(0, 2);
        const end = str.slice(-2);
        const middleAsterisks = '*'.repeat(str.length - 4);
  
        return `${start}${middleAsterisks}${end}`;
      },
      startTimer() {
        const getNextTargetDate = () => {
            const now = moment.utc(); // Use UTC
            let targetDate = moment.utc().month(now.month()).date(14).startOf('day');

            if (now.isAfter(targetDate)) {
            targetDate.add(1, 'month');
            }

            return targetDate;
        };

        let targetDate = getNextTargetDate();

        let timerInterval;

        const updateTimer = () => {
            const nowUtc = moment.utc(); // Use UTC
            const distance = targetDate.diff(nowUtc);

            if (distance < 0) {
            this.timer = 'Leaderboard ended';
            this.init();
            clearInterval(timerInterval);

            targetDate = getNextTargetDate();
            this.startTimer();
            } else {
            const duration = moment.duration(distance);
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            this.timer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
            }
        };

        updateTimer();
        timerInterval = setInterval(updateTimer, 1000);
     }
   },
    mounted() {
      this.init();
      this.startTimer();
    },
  };
  </script>
  
  <style scoped>
  </style>
  